<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">{{ title }}境外旅遊</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <button
          class="button text-dark-5 bg-theme-5 shadow-md mr-2 flex items-center"
          @click="back"
        >
          <FontAwesome icon="arrow-left" type="fas" class="w-4 h-4 mr-1" />
          取消
        </button>
        <button
          class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
          @click="save"
        >
          <FontAwesome icon="check-circle" type="fas" class="w-4 h-4 mr-1" />
          儲存
        </button>
      </div>
    </div>

    <div class="grid grid-cols-12 gap-3">
      <div class="intro-y col-span-12 lg:col-span-9">
        <div class="intro-y box p-5 mt-5">
          <div class="grid grid-flow-row gap-2">
            <div class="grid grid-flow-row gap-1">
              <label>境外旅遊名稱</label>
              <vxe-input
                placeholder="境外旅遊名稱"
                class="w-full"
                v-model.trim="validate.Name.$model"
                :class="{ 'bg-red-200': validate.Name.$error }"
              ></vxe-input>
              <template v-if="validate.Name.$error">
                <div
                  v-for="(error, index) in validate.Name.$errors"
                  :key="index"
                  class="text-theme-6 mt-2"
                >
                  {{ error.$message }}
                </div>
              </template>
            </div>
            <div class="grid grid-flow-row gap-1">
              <label>地址</label>
              <div class="flex flex-row space-x-1">
                <vxe-select
                  v-model="form.formData.Address.City"
                  placeholder="縣市"
                  @change="cityChange"
                >
                  <vxe-option
                    v-for="item in city"
                    :key="item.name"
                    :value="item.name"
                    :label="item.name"
                  ></vxe-option>
                </vxe-select>
                <vxe-select
                  v-model="form.formData.Address.District"
                  placeholder="區"
                >
                  <vxe-option
                    v-for="item in currentCity.districts"
                    :key="item.zip"
                    :value="item.name"
                    :label="item.zip + ' ' + item.name"
                  ></vxe-option>
                </vxe-select>
                <vxe-input
                  placeholder="地址"
                  class="w-full"
                  v-model.trim="validate.Address.Line.$model"
                  :class="{ 'bg-red-200': validate.Address.Line.$error }"
                ></vxe-input>
              </div>
              <template v-if="validate.Address.Line.$error">
                <div
                  v-for="(error, index) in validate.Address.Line.$errors"
                  :key="index"
                  class="text-theme-6 mt-2"
                >
                  {{ error.$message }}
                </div>
              </template>
            </div>

            <div class="grid grid-flow-row gap-1">
              <label>網頁連結</label>
              <vxe-input
                placeholder="網頁連結"
                class="w-full"
                v-model="form.formData.LinkUrl"
              ></vxe-input>
            </div>

            <div class="grid grid-flow-row gap-1">
              <label>旅遊項目說明</label>

              <div>
                <div class="wysiwyg-box">
                  <div class="container-fluid container-xl">
                    <div class="row">
                      <div class="col">
                        <CKEditor
                          v-model="form.formData.Policy.Content"
                          :editor="classicEditor"
                          :config="simpleEditorConfig"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="intro-y box p-5 mt-5">
          <div
            class="flex items-center border-b border-gray-200 dark:border-dark-5 flex-col"
          >
            <h2 class="font-medium text-base mr-auto">旅遊情境照片</h2>
            <!-- <div class="dropdown ml-auto">
              <a class="dropdown-toggle w-5 h-5 block" href="javascript:;">
                <MoreHorizontalIcon
                  class="w-5 h-5 text-gray-600 dark:text-gray-300"
                />
              </a>
              <div class="dropdown-box w-40">
                <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                  <a
                    href=""
                    class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  >
                    <PlusIcon class="w-4 h-4 mr-2" /> Add Category
                  </a>
                  <a
                    href=""
                    class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  >
                    <SettingsIcon class="w-4 h-4 mr-2" />
                    Settings
                  </a>
                </div>
              </div>
            </div> -->
          </div>
          <div class="w-full pt-2">
            <slider-editor
              ref="sliderEditor"
              :navigation="true"
              :width="sliderWidth"
              :filePostAction="uploadAction"
              :formOptions="pictureFormOptions"
              v-model="form.formData.Gallery.Pictures"
              @add="onSliderEdit"
              @edit="onSliderEdit"
              @save="onSliderSave"
              @remove="onSliderRemove"
            />
            <!-- <file-uploader
              id="product-photo-uri"
              class="h-72 border rounded"
              mode="image"
              defaultImage="http://placehold.jp/639x300.png?text=950x450"
              :limitedHeight="300"
              :limitedWidth="639"
              :canDelete="canEdit"
              :modelValue="form.formData.Photo?.Uri"
              :action="uploadAction"
              :autoUpload="true"
              @update:modelValue="
                (value) => {
                  if (form.formData) {
                    if (form.formData.Photo) form.formData.Photo.Uri = value;
                    else form.formData.Photo = { Uri: value };
                  }
                }
              "
            /> -->
          </div>
        </div>

        <!-- <div class="intro-y box p-5 mt-5">
          <div
            class="flex items-center border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">旅遊內容說明</h2>
          </div>
          <div>
            <CKEditor
              v-model="form.formData.Traffic.Content"
              :editor="classicEditor"
              :config="simpleEditorConfig"
            />
          </div>
        </div> -->
        <div class="intro-y box p-5 mt-5">
          <div
            class="flex items-center border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">簡介</h2>
          </div>
          <div>
            <div class="wysiwyg-box">
              <div class="container-fluid container-xl">
                <div class="row">
                  <div class="col">
                    <CKEditor
                      v-model="form.formData.Introduction.Content"
                      :editor="classicEditor"
                      :config="simpleEditorConfig"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="intro-y box p-5 mt-5">
          <div
            class="flex items-center border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">入住政策</h2>
          </div>
          <div>
            <CKEditor
              v-model="form.formData.Policy.Content"
              :editor="classicEditor"
              :config="simpleEditorConfig"
            />
          </div>
        </div> -->
      </div>
      <div class="intro-y col-span-12 lg:col-span-3">
        <div class="intro-y box p-5 mt-5">
          <div
            class="flex items-center border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">價格設定</h2>
          </div>
          <div class="">
            <div class="flex flex-col p-1 pt-3">
              <label>市價</label>
              <vxe-input
                type="number"
                v-model="form.formData.FixedPrice"
                placeholder="輸入市價"
              ></vxe-input>
            </div>
            <!-- <div class="flex flex-col p-1 pt-3">
              <label>成本價</label>
              <vxe-input
                v-model="form.formData.Phone"
                placeholder="輸入實際銷售金額"
              ></vxe-input>
            </div> -->
            <div class="flex flex-col p-1 pt-3">
              <label>促銷價</label>
              <vxe-input
                type="number"
                v-model="form.formData.Price"
                placeholder="輸入實際銷售金額"
              ></vxe-input>
            </div>
          </div>
        </div>
        <div class="intro-y box p-5 mt-5">
          <!-- <div
            class="flex items-center border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">發佈設定</h2>
          </div> -->
          <div class="">
            <div class="flex flex-col p-1 pt-3">
              <label>聯絡人</label>
              <vxe-input
                v-model="form.formData.Contact"
                placeholder="聯絡人"
              ></vxe-input>
            </div>
            <div class="flex flex-col p-1 pt-3">
              <label>電話</label>
              <vxe-input
                v-model="form.formData.Phone"
                placeholder="電話"
              ></vxe-input>
            </div>
            <div class="flex flex-col p-1">
              <label>傳真</label>
              <vxe-input
                v-model="form.formData.Fax"
                placeholder="傳真"
              ></vxe-input>
            </div>
            <div class="flex flex-col p-1">
              <label>Email</label>
              <vxe-input
                v-model="form.formData.Email"
                placeholder="Email"
              ></vxe-input>
            </div>
          </div>
        </div>
        <div class="intro-y box mt-5 p-5">
          <div
            class="flex items-center border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">狀態</h2>
          </div>
          <!-- <div class="flex flex-col p-1">
            <label>類型</label>
            <vxe-select v-model="form.formData.Type" placeholder="類型">
              <vxe-option :value="0" label="飯店"></vxe-option>
              <vxe-option :value="1" label="民宿"></vxe-option>
            </vxe-select>
          </div> -->
          <div class="flex flex-col p-1">
            <label>銷售起始日（起賣）</label>
            <vxe-input
              v-model="form.formData.StartDate"
              type="date"
              clearable
              placeholder=""
            ></vxe-input>
          </div>
          <div class="flex flex-col p-1">
            <label>旅遊截止日（停賣）</label>
            <vxe-input
              v-model="form.formData.EndDate"
              type="date"
              clearable
              placeholder=""
            ></vxe-input>
          </div>

          <div class="flex flex-col p-1 pt-3">
            <label>發佈狀態</label>
            <vxe-switch
              v-model="form.formData.Published"
              open-label="上架"
              close-label="下架"
              :disabled="!canEdit"
            ></vxe-switch>
          </div>
          <div class="flex flex-col p-1 pt-3">
            <label>庫存檢查</label>
            <vxe-switch
              v-model="form.formData.CheckStock"
              open-label="檢查"
              close-label="不檢查"
              :disabled="!canEdit"
            ></vxe-switch>
          </div>
          <!-- <div class="flex flex-col p-1">
            <label>熱門文章</label>
            <vxe-switch
              v-model="form.formData.IsHot"
              open-label="熱門"
              close-label="一般"
              :disabled="!canEdit"
            ></vxe-switch>
          </div>
          <div class="flex flex-col p-1">
            <label>最新文章</label>
            <vxe-switch
              v-model="form.formData.IsNew"
              open-label="最新"
              close-label="一般"
              :disabled="!canEdit"
            ></vxe-switch>
          </div> -->
        </div>
        <div class="intro-y box p-5 mt-5">
          <div
            class="flex items-center border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">座標設定</h2>
          </div>
          <div class="">
            <div class="flex flex-col p-1 pt-3">
              <label>經度</label>
              <vxe-input
                type="float"
                v-model="form.formData.Latitude"
                placeholder="經度"
              ></vxe-input>
            </div>
            <div class="flex flex-col p-1">
              <label>緯度</label>
              <vxe-input
                type="float"
                v-model="form.formData.Longitude"
                placeholder="緯度"
              ></vxe-input>
            </div>
          </div>
        </div>

        <div class="intro-y box mt-5 p-5">
          <div class="grid grid-flow-row gap-1">
            <label>標籤</label>
            <vue-tags-input
              v-model="tag"
              :autocomplete-items="filteredItems"
              :tags="tags"
              @tags-changed="tagChanged"
            />
          </div>
        </div>
        <div class="intro-y box mt-5 p-5">
          <div class="grid grid-flow-row gap-1">
            <label>區域分類</label>
            <select-box
              v-bind="regionSelectOptions"
              v-model="selectedRegions"
            />
          </div>
          <div class="grid grid-flow-row gap-1">
            <label>旅遊種類</label>
            <select-box
              v-bind="distanceSelectOptions"
              v-model="selectedDistance"
            />
          </div>
        </div>
        <div class="intro-y box mt-5 p-5">
          <div class="grid grid-flow-row gap-1">
            <label>備註</label>
            <vxe-textarea
              placeholder=""
              class="w-full"
              v-model="form.formData.Remark"
            ></vxe-textarea>
          </div>
        </div>
      </div>

      <!-- <div class="intro-y col-span-12 lg:col-span-9">
        <div class="intro-y box p-5"></div>
      </div>
      <div class="intro-y col-span-12 lg:col-span-3">
        <div class="intro-y box p-5"></div>
      </div> -->
    </div>

    <div class="w-full sm:w-auto flex pt-4 sm:mt-0">
      <button
        class="button text-dark-5 bg-theme-5 shadow-md mr-2 flex items-center"
        @click="back"
      >
        <FontAwesome icon="arrow-left" type="fas" class="w-4 h-4 mr-1" />
        取消
      </button>
      <button
        class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
        @click="save"
      >
        <FontAwesome icon="check-circle" type="fas" class="w-4 h-4 mr-1" />
        儲存
      </button>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import CloudFun, {
  Model,
  defineComponent,
  ref,
  onMounted,
  reactive,
  computed,
  Sorting,
  SortOrder,
  Condition,
  Operator,
  LogicalConnective,
} from "@cloudfun/core";
import { useRouter, useRoute, onBeforeRouteLeave } from "vue-router";
import FileUploader from "@/cloudfun/components/FileUploader.vue";
import UploadAdapterPlugin from "@/global-components/ckeditor/upload-adapter-plugin";
import ClassicEditor from "@/global-components/ckeditor/classic-editor";
import formatDate from "xe-utils/toDateString";
import "@/global-components/ckeditor/styles.css";
import "@/assets/css/wysiwyg.css";
import VueTagsInput from "@sipec/vue3-tags-input";
import { ProductType, AttributeType } from "@/assets/static-data";
import { CustomFigureAttributes } from "@/global-components/ckeditor/custom";

import {
  required,
  //   minLength,
  //   maxLength,
  //   email,
  //   url,
  //   integer,
} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { toRefs, watch, nextTick } from "vue";
import { deepEqual } from "fast-equals";
import _ from "lodash";
import { v1 as uuidv1 } from "uuid";
import SliderEditor from "@/cloudfun/components/SliderEditor.vue";
import SelectBox, {
  SelectBoxOptions,
} from "@/cloudfun/components/SelectBox.vue";
export default defineComponent({
  components: {
    FileUploader,
    VueTagsInput,
    SliderEditor,
    SelectBox,
  },
  props: {
    id: {
      type: Number,
    },
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();
    const model = CloudFun.current?.model;
    const canEdit = true;
    const tag = ref("");
    const tags = ref([]);
    const hasChanged = ref(false);
    const files = ref([]);
    const tagsItems = ref([]);
    const original = reactive({ formData: {} });
    const sliderEditor = ref({});
    const city = ref([]);
    const currentCity = ref([]);
    const regions = ref([]);
    const selectedRegions = ref([]);
    const selectedDistance = ref([]);
    const params = reactive({
      insertRows: [],
      updateRows: [],
      deleteRows: [],
    });
    const form = reactive({
      model: "insert",
      formData: {
        Id: 0,
        Name: null,
        Type: ProductType.OverSea,
        Phone: null,
        Fax: null,
        Address: {
          PostalCode: null,
          City: null,
          District: null,
          Line: null,
        },
        Published: false,
        LinkUrl: null,
        Latitude: null,
        Longitude: null,
        Traffic: {
          Content: "",
        },
        Map: null,
        Introduction: {
          Content: "",
        },
        Remark: null,
        Gallery: {
          Pictures: null,
        },
        Policy: {
          Content: "",
        },
        ShortIntroduction: {
          Content: "",
        },
        StarLevel: null,
        Tags: [],
        Attributes: [],
      },
    });

    const formRules = {
      Name: {
        required,
        // minLength: minLength(2),
      },
      Address: {
        Line: {
          required,
          // email,
        },
      },

      //   },
    };
    const validate = useVuelidate(formRules, toRefs(form.formData));
    const pictureFormOptions = {
      titleWidth: 40,
      titleAlign: "right",
      items: [
        {
          field: "Name",
          title: "名稱",
          span: 14,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true },
          },
        },
        {
          field: "Ordinal",
          title: "排序",
          span: 10,
          titleWidth: 40,
          itemRender: {
            name: "$input",
            props: { type: "number", placeholder: "請輸入數字" },
          },
        },
        {
          field: "Remark",
          title: "備註",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true },
          },
        },
        {
          span: 24,
          align: "right",
          itemRender: {
            name: "$buttons",
            children: [
              { props: { type: "submit", content: "確定", status: "primary" } },
              { props: { type: "reset", content: "清除" } },
            ],
          },
        },
      ],
      rules: {
        Name: [{ type: "string", max: 128 }],
      },
    };

    const regionSelectOptions = {
      rowId: "Id",
      multiselect: true,
      placeholder: "選擇區域",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
      ],
      promises: {
        find: (value) => model?.dispatch("attributeCategory/find", value),
        query: (params) => {
          if (params.condition) params.condition.and("Type", Operator.Equal, 0);
          return model?.dispatch("attributeCategory/query", params);
        },
      },
    };

    const distanceSelectOptions = {
      rowId: "Id",
      multiselect: true,
      placeholder: "選擇旅遊種類",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
      ],
      promises: {
        find: (value) => model?.dispatch("attributeCategory/find", value),
        query: (params) => {
          if (params.condition)
            params.condition.and("Type", Operator.Equal, AttributeType.Ticket);
          return model?.dispatch("attributeCategory/query", params);
        },
      },
    };

    function schemaCustomization(editor) {
      editor.model.schema.extend("$block", {
        allowAttributes: "classList",
      });
      editor.conversion.attributeToAttribute({
        model: {
          key: "classList",
        },
        view: {
          key: "class",
        },
      });
    }

    const classicEditor = ClassicEditor;
    const simpleEditorConfig = {
      extraPlugins: [UploadAdapterPlugin, CustomFigureAttributes],
      toolbar: {
        shouldNotGroupWhenFull: true,
        items: [
          "sourceEditing",
          "|",
          "heading",
          "|",
          "fontFamily",
          "fontSize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "underline",
          "bulletedList",
          "numberedList",
          "alignment",
          "outdent",
          "indent",
          "highlight",
          "insertTable",
          "|",
          "link",
          "blockQuote",
          "imageInsert",
          "mediaEmbed",
          // 'codeBlock',
          "htmlEmbed",
          "|",
          "undo",
          "redo",
        ],
      },
      removePlugins: ["Markdown"],
      image: {
        toolbar: [
          "imageStyle:inline",
          "imageStyle:block",
          "imageStyle:side",
          "|",
          "toggleImageCaption",
          "imageTextAlternative",
          "linkImage",
        ],
      },
      table: {
        contentToolbar: [
          "tableColumn",
          "tableRow",
          "mergeTableCells",
          "tableCellProperties",
          "tableProperties",
        ],
      },
      heading: {
        options: [
          {
            model: "paragraph",
            title: "Paragraph",
            class: "ck-heading_paragraph",
          },
          {
            model: "heading1",
            view: { name: "h1", classes: "font-bold custom-big" },
            title: "Heading 1",
            class: "ck-heading_heading1",
          },
          {
            model: "heading2",
            view: { name: "h2", classes: "font-bold custom-default" },
            title: "Heading 2",
            class: "ck-heading_heading2",
          },
        ],
      },
      fontSize: {
        options: [
          {
            title: "特小",
            model: "custom-tiny-tool",
            view: { name: "span", classes: "custom-tiny" },
          },
          {
            title: "小",
            model: "custom-small-tool",
            view: { name: "span", classes: "custom-small" },
          },
          {
            title: "預設",
            model: "custom-default-tool",
            view: { name: "span", classes: "custom-default" },
          },
          {
            title: "大",
            model: "custom-big-tool",
            view: { name: "span", classes: "custom-big" },
          },
          {
            title: "特大",
            model: "custom-huge-tool",
            view: { name: "span", classes: "custom-huge" },
          },
        ],
      },
    };

    model?.dispatch("tag/query").then(
      (payload) => {
        console.log("tags", payload);
        tagsItems.value = payload.map((item) => {
          return { text: item.Name, id: item.Id };
        });
      },
      (failure) => {}
    );

    onBeforeRouteLeave((to, from) => {
      console.log("hasChanged", _.isEqual(original.formData, form.formData));
      if (hasChanged.value) {
        const answer = window.confirm("尚未儲存，確定離開？");
        return answer;
      }
    });

    watch(form.formData, (first, second) => {
      if (!_.isEqual(original.formData, second)) hasChanged.value = true;

      if (form.formData.Address.City) {
        const item = city.value.filter(
          (el) => el.name === form.formData.Address.City
        );
        if (item && item.length > 0) currentCity.value = item[0];
        else currentCity.value = null;
      }
    });

    const filteredItems = computed((i) => {
      return tagsItems.value.filter((i) => {
        return i.text.toLowerCase().indexOf(tag.value.toLowerCase()) !== -1;
      });
    });

    const title = computed(() => {
      return form.model === "insert" ? "新增" : "編輯";
    });

    onMounted(() => {
      city.value = model?.enums.CityDistricts;

      if (route.query && route.query.id) {
        model?.dispatch("hotel/find", route.query.id).then(
          (payload) => {
            console.log("payload", payload);
            Object.assign(form.formData, payload);
            form.model = "update";
            original.formData = _.cloneDeep(form.formData);
            if (form.formData.Tags && form.formData.Tags.length > 0) {
              tags.value = form.formData.Tags.map((item) => {
                return { text: item.Tag.Name, id: item.Tag.Id };
              });
            }
            sliderEditor.value.reload(form.formData.Gallery.Pictures);

            if (form.formData.Attributes) {
              const filter = form.formData.Attributes.filter(
                (el) => el.AttributeCategory.Type == 0
              );
              selectedRegions.value = filter.map((item) => {
                return item.AttributeCategoryId;
              });
              const filter2 = form.formData.Attributes.filter(
                (el) => el.AttributeCategory.Type == AttributeType.Ticket
              );
              selectedDistance.value = filter2.map((item) => {
                return item.AttributeCategoryId;
              });
            }

            console.log("selectedRegions", selectedRegions.value);
            console.log("selectedDistance", selectedDistance.value);
            nextTick(() => {
              hasChanged.value = false;
            });
          },
          (failure) => {
            console.log("failure", failure);
          }
        );
      }
    });

    const tagChanged = (newTags) => {
      tags.value = newTags;
    };

    const cityChange = (value) => {
      if (form.formData.Address.City != value)
        form.formData.Address.District = null;
      const item = city.value.filter((el) => el.name === value);
      if (item && item.length > 0) currentCity.value = item[0];
      else currentCity.value = null;
    };

    // modal 執行 新增or修改
    const save = () => {
      validate.value.$touch();
      console.log(validate.value);
      if (validate.value.$invalid) return;
      var obj = files.value;
      const formData = new FormData();
      if (obj.length > 0) {
        formData.append("file", obj[0]["file"]);
      }
      form.formData.Tags.length = 0;

      if (tags.value.length > 0) {
        tags.value.forEach((item) => {
          form.formData.Tags.push({
            Tag: { Id: item.id, Name: item.text },
            Hotel: {},
            TagId: item.id,
            HotelId: form.formData.Id,
          });
        });
      }

      form.formData.Attributes.length = 0;

      if (selectedRegions.value.length > 0) {
        selectedRegions.value.forEach((item) => {
          form.formData.Attributes.push({
            HotelId: form.formData.Id,
            AttributeCategoryId: item,
          });
        });
      }

      if (selectedDistance.value.length > 0) {
        selectedDistance.value.forEach((item) => {
          form.formData.Attributes.push({
            HotelId: form.formData.Id,
            AttributeCategoryId: item,
          });
        });
      }
      debugger;
      if (form.formData.Id === 0) params.insertRows = [form.formData];
      else params.updateRows = [form.formData];
      model
        .dispatch("hotel/save", params)
        .then(
          (resp) => {
            console.log("update success", resp);
            CloudFun.send("info", {
              subject: form.formData.Id == 0 ? "新增成功" : "更新成功",
              content:
                form.formData.Id == 0 ? "境外資料新增完成" : "境外資料更新完成",
            });
            hasChanged.value = false;
            router.back();
          },
          (failure) => {
            console.log("update error", failure);
            CloudFun.send("error", { subject: "操作失敗！", content: failure });
          }
        )
        .finally();

      e.preventDefault();
    };

    return {
      classicEditor,
      simpleEditorConfig,
      form,
      canEdit,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/files`,
      tag,
      tags,
      validate,
      save,
      router,
      route,
      files,
      tagChanged,
      original,
      filteredItems,
      title,
      pictureFormOptions,
      sliderEditor,
      params,
      city,
      currentCity,
      cityChange,
      regions,
      regionSelectOptions,
      distanceSelectOptions,
      selectedDistance,
      selectedRegions,
    };
  },
  methods: {
    back() {
      this.$router.back();
    },
    onSliderEdit(image, callback) {
      debugger;
      image.GalleryId = this.form.formData.Gallery.Id;
      callback();
    },
    async onSliderRemove(image, callback) {
      await this.$model.dispatch("picture/delete", image.Id);
      callback();
    },
    async onSliderSave(params, callback) {
      debugger;
      await this.$model.dispatch("picture/save", params).then(
        () => {
          this.$model
            .dispatch("pictureGallery/find", this.form.formData.Gallery.Id)
            .then(
              (entity) => {
                debugger;
                if (entity.Pictures)
                  entity.Pictures = entity.Pictures.sort(
                    (a, b) => a.Ordinal - b.Ordinal
                  );
                this.$refs.sliderEditor.reload(
                  entity.Pictures.sort((a, b) => a.Ordinal - b.Ordinal)
                );
              },
              (reason) => {
                CloudFun.send("error", {
                  subject: "重載失敗",
                  content: reason,
                });
              }
            );
        },
        (reason) => {
          CloudFun.send("error", { subject: "保存失敗", content: reason });
        }
      );
      callback();
    },
  },
});
</script>

<style scoped></style>
